import { useMemo } from 'react';

import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { WebPage, WithContext } from 'schema-dts';

import { Language } from '@hultafors/shared/types';

import { useGlobal, useOrganization } from '@hultafors/solidgear/hooks';

import { ShoeGuide } from '../ShoeGuide/ShoeGuide';

import { PageStyled } from './page.styled';

const MicroData = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.MicroData),
);

interface PageProps {
  metadata?: any;
  market?: string;
  children?: React.ReactNode;
  canonicalPath?: string;
  hrefLanguages?: Language[];
  testId?: string;
  attachFooterToBottom?: boolean;
  canonical?: string;
  microData?: Partial<WebPage>;
}

export const Page: React.FC<PageProps> = ({
  metadata,
  hrefLanguages,
  testId = '',
  children,
  attachFooterToBottom,
  canonicalPath,
  microData,
}) => {
  const { displayLanguageSelector, settings } = useGlobal();
  const { asPath } = useRouter();
  const organization = useOrganization();
  const pageMicroData: WithContext<WebPage> = useMemo(() => {
    return {
      '@context': 'https://schema.org',
      '@type': 'WebPage',
      ...microData,
    };
  }, [microData]);

  const url = asPath.split('?')[0];
  let canonical = `https://www.solidgearfootwear.com${url}`.replace(/\/$/, '');
  if (settings?.hostname) {
    canonical = `https://${settings.hostname}${url}`.replace(/\/$/, '');
  }

  if (canonicalPath) {
    canonical = canonicalPath;
  }

  // Removes the trailing slash at the end of the url
  function getSlicedUrl(url?: string) {
    if (url?.endsWith('/')) {
      return url.slice(0, -1);
    }
    return url;
  }

  function languagesMapper({
    lang,
    urlPrefix,
    hostname: marketHostname,
  }: Language) {
    const hrefLang = lang.toLowerCase();
    let path = getSlicedUrl(url);
    if (urlPrefix) {
      path = `/${urlPrefix}${getSlicedUrl(url)}`;
    }
    const href = `https://${marketHostname}${path}`;
    if (hrefLang === 'en') {
      return (
        <link rel="alternate" hrefLang="x-default" href={href} key={href} />
      );
    }
    return <link rel="alternate" hrefLang={hrefLang} href={href} key={href} />;
  }

  // Generate schema.org JSON-LD for the organization

  return (
    <>
      <Head>
        {canonical && <link rel="canonical" href={canonical} key="canonical" />}
        {metadata?.title && (
          <>
            <title>{metadata?.title}</title>
            <meta property="og:title" content={metadata.title} />
            <meta name="twitter:title" content={metadata.title} />
          </>
        )}
        {metadata?.description && (
          <>
            <meta name="description" content={metadata.description} />
            <meta property="og:description" content={metadata.description} />
            <meta name="twitter:description" content={metadata.description} />
          </>
        )}
        {metadata?.type && <meta property="og:type" content={metadata.type} />}
        {metadata?.image?.url && (
          <>
            <meta property="og:image" content={metadata.image.url} />
            <meta property="twitter:image" content={metadata.image.url} />
          </>
        )}
        {metadata?.image?.width && (
          <meta property="og:image:width" content={`${metadata.image.width}`} />
        )}
        {metadata?.image?.height && (
          <meta
            property="og:image:height"
            content={`${metadata.image.height}`}
          />
        )}
        {metadata?.image?.alt && (
          <meta property="twitter:image:alt" content={metadata.image.alt} />
        )}
        <meta name="twitter:card" content="summary" />

        {hrefLanguages?.map(languagesMapper)}
      </Head>
      <ShoeGuide />
      <PageStyled
        $isLanguageSelectorVisible={displayLanguageSelector}
        $attachFooterToBottom={attachFooterToBottom}
        data-test-id={testId}
      >
        {children}
      </PageStyled>
      <MicroData data={organization} />
      {pageMicroData && <MicroData data={pageMicroData} />}
    </>
  );
};
